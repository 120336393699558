.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

/*from other branch*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
    html, body, .page {
        width: 210mm;
        height: 297mm;
        background: #FAFAFA;
    }

    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }

    .pagebreak {
        page-break-before: always;
    }

    /* page-break-after works, as well */
}